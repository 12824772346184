#intro-signed {
  position: absolute;
  padding: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.798);
  z-index: 1;
  height: 615px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

#intro-signed>video {
  width: 800px;
  position: absolute;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  left: 50%;
  margin-left: -400px;
  margin-top: 50px;
  transition: all 0.5s ease-in-out;
  transform: translateY(0);

}

#intro-signed.closed {
  transform: translateY(-200%);
}

.close-button {
  color: rgb(255, 255, 255);
  position: absolute;
  right: 10px;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}

#intro-signed.starting {
  transform: translateY(-200%);
}