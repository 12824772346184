.App {
  text-align: justify;
}

.App-logo {
  height: 150px;
  margin-right: 20px;
}

.App-header {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: #333;
  position: relative;
}
.header-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 20px;
}


.header-icons svg {
  font-size: 32px;
  color: #337AB7;
}

.header-icons a,
.header-icons svg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}


.App-link {
  color: #61dafb;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #ECECEC;
  flex-wrap: wrap;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.text-content {
  flex: 1;
  padding: 20px;
  max-width: 600px;
  margin-right: 30px;
  overflow-y: auto;
}

.background-carousel {
  flex: 1;
  max-width: 700px;
  padding: 20px;
  margin-right: 100px;
  margin-top: 40px;
  position: relative;
  z-index: 0;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  background-color: #FFF;
}

.video-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.background-image {
  width: 100%;
  object-fit: cover;
}

.videoPlayer {
  max-width: 66%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 100px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.video-title {
  width: 100%;
  max-width: 66%;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.testimonies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
}

.translation-teams-text {
  font-size: 18px;
  margin-left: 20px;
  font-weight: bold;
}

.translation-teams-container {
  display: flex;
  justify-content: center;
  background-color: #ECECEC;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin-top: 20px;
}

.translation-teams-text,
.translation-teams-video {
  flex: 1;
  max-width: 66%;
  padding: 20px;
}

@media (max-width: 768px) {

  .translation-teams-text,
  .translation-teams-video {
    max-width: 100%;
  }
}

.translation-teams-text li sup {
  font-size: 10px;
}

.translation-teams-text li p {
  margin-bottom: 2px;
}

.translation-teams-video .videoPlayer {
  margin-top: 10px;
}

.translation-teams-container h3 {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

.translation-app-request {
  position: absolute;
  top: 60px;
  right: 30px;
}

.translation-app-request>button {
  border: none;
  padding: 10px 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  font-size: 18px;
  cursor: pointer;
  color: #FFF;
  background-color: #337AB7;
  transition: all 0.3s ease-in-out;

}

.translation-app-request>button:hover {
  background-color: #DFDFDF;
  color: #3F3F3F;

}

.App-footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-section {
  margin: 10px;
  padding: 0 20px;
}

.footer-section h4 {
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 20px 0;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-images {
  display: flex;
  justify-content: flex-end;
}

.footer-images img {
  margin-left: 10px;
}
