.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 20px;
    background-color: #f5f5f5;
}

button {
    padding: 10px 20px;
    background-color: #337AB7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
}

form h2 {
    color: #333;
}

form label {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

form input[type="text"],
form input[type="date"],
form textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 30px;
}

form input[type="submit"] {
    background-color: #337AB7;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.success {
    position: fixed;
    top: 40px;
    width: 400px;
    left: 50%;
    cursor: default;
    margin-left: -200px;
    background-color: #1C8F1C;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    color: #FFF;
}

.button-sending {
    opacity: 0.5;
}